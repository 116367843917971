@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&family=Comfortaa:wght@700&family=Kalam:wght@700&family=Kristi&display=swap');

$breakpoints: (
  small: 480px,
  medium: 850px,
  large: 1000px,
  x-large: 1130px,
);

body {
  background: black;
}

*, body {
  font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

@import './color';
@import './bbtDetails';
@import './card';
@import './font';
@import './gallary';
@import './home';
@import './modal';
@import './navbar';
@import './overview';
@import './progressBar';
@import './wallet';
@import './loader';

html {
  color: white;
  background: black;
}

.no-show-mobile {
  display: inline-block;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    display: none;
  }
}
.show-mobile {
  display: none;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    display: inline-block;
  }
}

.site-title {
  font-size:$nav-font-size;
}

.code {
  font-family: 'Source Code Pro', monospace !important;
}

.page-container {
  margin: 10rem 10vw;
  padding: 10px;
  min-height: 60vh;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    margin: 10rem 5vw;
    padding: 20px;
  }
  @media screen and (min-width: map-get($breakpoints, large)) {
    max-width: 1080px;
    margin: 10rem auto;
    padding: 10px 30px;
  }
}

h2 {
  &::selection {
    background: white;
  }
}
h1, p, h3, div {
  &::selection {
    background: $main-color;
  }
}

h2 {
  margin: 2rem 0 .5rem 0;
  font-weight: 800;
  font-size: 2.2rem;
  letter-spacing:1px;
  color: $main-color;
}
h3 {
  margin: .2rem 0 1rem 0;
  font-weight: 600;
  font-size: 18px;
  letter-spacing:1px;
}
p, ul, ol {
  font-size: $content-font-size;
  line-height: $content-font-size*1.8;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
  letter-spacing:1px;
  font-weight: 400;

  .firstWord {
    font-size: 2rem;
  }
}

a .btn, a:-webkit-any-link {
  text-decoration: none !important;
}
.btn {
  border: none;
  background-color: rgba($main-color, 1);
  color: black;
  cursor: pointer;
  padding: 1.2em;
  border-radius: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 38px;
  margin: 5px;
  line-height: 18px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.sm {
    padding: .8em;
    font-size: 14px;
    line-height: 14px;
  }
  &.loading-true {
    cursor: default;
    opacity: 0.8;
  }
  &:hover{
    &.loading-false {
      background-color: lighten($main-color, 10%);
    }
  }
  &:active{
    &.loading-false {
      background-color: darken($main-color, 5%);
    }
  }
  &:focus {
    outline: none;
  }
  .loading {
    border: 3px solid rgba(white, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin: 0 auto;
    animation: spin 0.5s linear infinite;
  }
  &.secondary {
    background-color: transparent;
    color: rgba($main-color, 0.5);
  }

  &.danger {
    background-color: rgba(#da4d4d, 1);
    color: white;
    &:hover{
      background-color: rgba(red, 0.8);
    }
  }

}

@Keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.outter-container {
  display: flex;
  flex-direction: row;
  justify-content:center;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    flex-direction: column;
    align-items: center;
  }
  .inner-container {
    display: flex;
    flex-direction: column;
  }
}

.introduction {
  width: 600px;
  padding: 2rem;
}



//Stars
.stars-group {
  position: absolute;
  top: -100px;
}
.star {
  background: transparent;
  color: $main-color;
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 50px;
  z-index: -1;
}
@keyframes blink {
  from {opacity: 0;}
  to {opacity: 1;}
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px -2px 0px rgba(white, 0.075);
  .links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    color: white;
    &:hover{
      opacity: 0.8;
    }
  }
  a.social {
    opacity: 1;
    display: inline-block;
    color: black;
    font-size: 0.8rem;
    padding: 0px 5px;
    background: white;
    margin: 5px;
    &.discord:hover {
      background: #6F85D3;
      color: white;
    }
    &.twitter:hover {
      background: #1EA1F2;
      color: white;
    }
    &.twitter:active, &.medium:active {
      opacity: 0.9;
    }
    &.medium:hover {
      background: rgba(white, 0.1);
      color: white;
    }
    &.discord:hover {
      background: #5865F2;
      color: white;
    }
  }
}

// image loader

.loader-component{
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  padding: 25px 0px;

  .circle-spinner {
    text-align: center;
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
  }

  .double-bounce1 {
    background-color: rgba(white, 1);
  }
  .double-bounce2 {
    background-color: rgba(white, 0.8);
  }
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 1.5s infinite ease-in-out;
    animation: sk-bounce 1.5s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }


  @-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px)}
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
  }

  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      opacity: 1;
    } 50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      opacity: 1;
    } 100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      opacity: 1;
    }
  }


  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
}

.leaderboard-note {
  display: inline-block;
  color: white;
  font-size: 1rem;
  text-transform: none;
  letter-spacing: 0px;
  align-self: center;
  &.wallet {
    font-size: .8rem;
  }
}
