
.dimmer {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(black, 0.3);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: all 0.5s ease-in-out;
  display: flex;
  backdrop-filter: blur(5px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 2em 0;
  }
}

.modal {
  transition: all 0.5s ease-in-out;
  max-width: 800px;
  position: relative;
  text-align: left;
  user-select: text;
  background-color: white;
  border-radius: 20px;
  z-index: 1000;
  &.primary > .title-group {
    background-color: rgba($main-color, 0.3);
    color: $black;
  }
  &.error > .title-group {
    background-color: rgba(#da4d4d, 1);
    color: white;
  }
  .title-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    @media screen and (max-width: map-get($breakpoints, small)) {
    }
    .modal-title {
      font-size: $content-font-size;
      line-height: $content-font-size;
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: 1.5rem;
      }
    }
    .close-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
  &.sm {
    width: 445px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin: 6px;
    }
  }
  &.md {
    width: 800px;
    min-width: 33vw;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 30vh;
      max-width: 90vw;
      min-width: 80vw;
    }
  }
  .modal-inner-content {
    padding: 30px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-height: 70vh;
    overflow-y: auto;
    text-align: center;
    &::-webkit-scrollbar-track {
      //full track
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      //bar
      opacity: 0;
      cursor: pointer;
    }

    input, textarea {
      outline: none;
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box; // padding doesn't add to width
      border: 1px solid rgba($black, 0.1);
      font-weight: 500;
      text-align: center;
      background: rgba($black, 0);
      font-size: 1.5rem;
      border-radius: 15px;
      margin: 10px 0px;
    }

    textarea {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      font-size: 1.2rem;
      //text-align: left;
      padding: 10px;
    }

    .input-length {
      color: $black;
      opacity: 0.7;
      float: right;
    }

    .prompt {
      color: $black;
      &.grey {
        opacity: 0.5;
      }
      div {
        line-height: 1.5rem;
        margin: 8px;
      }
    }

    button.btn {
      width: 100%;
      margin: 0px;
    }

    select {
      font-size: 1.5rem;
      background: rgba($black, 0.05);
      border: 0px;
      border-radius: 15px;
      padding: 10px 15px;
      outline: none;
    }

    .buy-input-container {
      padding-bottom: 30px;

      .notes {
        padding-top: 20px;
      }
      .prompt {
        margin: 5px 0px;
      }
      small {
        opacity: 0.5;
      }
    }

    .buy-modal-bear {
      color: $black;
      margin-left: 10px;
      font-size: 1.5rem;
      outline: none;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      height: 100%;
    }
  }
}
