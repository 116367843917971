.progress-bar {
  margin: 150px 10px;
  width: 100%;
  background-color: white;
  height:10px;
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  .tier {
    height: 10px;
    border-right: 5px solid $black;
    z-index: 2;
    position: relative;
    background-color: white;
    opacity: 0.8;
    &.tier-1 {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .progress {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
    &.tier-7 {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: 0px solid white;
      .progress {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    .count {
      position: absolute;
      top: -3rem;
      width:100%;
      text-align: center;
    }
    .price {
      position: absolute;
      bottom: -3rem;
      width: 100%;
      text-align: center;
    }
    .progress {
      background: linear-gradient(90deg, rgba(243,193,25,1) 0%, rgba(251,217,57,1) 100%);
      height: 10px;
    }
  }
}
