@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;600;700&display=swap');

.landing-page {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  .wrap {
    overflow-x: scroll;
    padding-bottom: 15px;
  }
  .contracts {
    background: rgba(white, 0.1);
    border-radius: 20px;
    font-size: 1.3rem;
    padding: 10px 10px 50px;
    line-height: 3rem;
    margin-top: 50px;
    div {
      font-family: 'Roboto Mono', monospace;
    }
    a {
      font-family: 'Roboto Mono', monospace;
      color: white;
      text-decoration: underline;
    }
  }
  .announcement-container {
    display: inline-block;
    @media screen and (max-width: map-get($breakpoints, small)) {
      font-size: 0.75rem;
    }
  }
  .announcement {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: auto;
    span {
      color: black;
      font-size: 0.6rem;
      background: white;
      padding: 1px 7px;
      border-radius: 5px;
      margin-right: 5px;
      animation: heartbeat 1s infinite;
    }
    @keyframes heartbeat
    {
      0%
      {
        transform: scale( .9 );
      }
      20%
      {
        transform: scale( 1 );
      }
      40%
      {
        transform: scale( .9 );
      }
      60%
      {
        transform: scale( 1 );
      }
      80%
      {
        transform: scale( .9 );
      }
      100%
      {
        transform: scale( .9 );
      }
    }
  }
  .btn {
    font-size: 1.7rem;
    border-radius: 20px;
    margin: auto;
    &:disabled:hover {
      transform: scale(1);
      cursor: default;
      background: $main-color;
    }
    &:hover{
      transition: all 0.05s ease-in-out;
      transform: scale(1.1);
    }
    &:active{
      transition: all 0s ease-in-out;
      transform: scale(1.15);
    }
  }
}

.hero-bears {
  padding: 20px 0px;
  img {
    margin: 20px auto;
    width: 100%;
    max-width: 800px;
    @media screen and (max-width: map-get($breakpoints, small)) {
    }
  }
}

.unrevealed-envolope {
  max-width: 400px;
  margin: auto;
  img {
    width: 100%;
  }
}

.stat-info {
  display: flex;
  flex-direction: column;
  padding: 100px 5px 0px;
  .info-group {
    width: 100%;
    max-width: 800px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    flex-direction: row;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: column;
    }
    .item {
      font-size: 1.2rem;
      color: white;
      font-weight: 600;
      padding-bottom: 50px;
      .number {
        color: $main-color;
        font-size: 5rem;
        font-weight: 900;
      }
      .units {
        font-size: 1.3rem;
        color: white;
        font-weight: 100;
      }
    }
  }
}


.coming-soon {
  padding: 50px 10px;

  h1 {
    //font-size: 1.5rem;
    //background-color: none;
    //display: inline-block;
    //border-radius: 50px;
    //padding: 5px 30px;
    //color: white;
    //border: 2px dashed white;
  }
}

.timeline-container {
  margin-top: 0px !important;
}


.bbt-gallery {
  width: 100vw;
  overflow-y: hidden;
  overflow-x: scroll;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 0px;
  &.no-scroll {
    overflow-x: hidden;
  }
  &:hover::-webkit-scrollbar {
    display: block;
  }

  &::-webkit-scrollbar {
    display: none;
    height: 0px;
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.01);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.1);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
  img {
    margin: 10px;
    width: 230px;
    height: 325px;
    //border-radius: 20px;
  }
}
