.page-container.wallet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    margin-right: 0px;
    margin-left: 0px;
  }
  .tutorial {
    text-align: center;
    .seperator {
      margin: 40px 0px;
    }
    .btn {
      width: 100%;
      position: relative;
      z-index: 1;
      div {
        position: absolute;
        left: 10px;
        z-index: 2;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }

      &.secondary {
        margin-bottom: 20px;
        margin-top: 20px;
        background-color: rgba(white, 0.08);
        color: white;
        opacity: 0.8;
        &.true {
          cursor: default;
          &:hover{
            background-color: rgba(white, 0.08);
            transition: .1s all ease-in-out;
            transform: scale(1);
          }
          &:active{
            background-color: rgba(white, 0.08);
            transform: scale(1);
          }
        }

        &.bsc {
          padding-left: 40px;
        }

        div {
          color: black;

          &.active {
            background-color: greenyellow;
          }
        }

        &:hover{
          background-color: rgba(white, 0.2);
          transition: .1s all ease-in-out;
          transform: scale(0.98);
        }
        &:active{
          background-color: rgba(white, 0.3);
          transform: scale(0.97);
        }
      }
    }
  }
  .logo {
    margin-bottom: 3rem;
    width: 150px;
  }
  .my-collection {
    text-align: left;
    width: 100%;
    margin-bottom: .8rem;
    @media (max-width: map-get($breakpoints, medium)){
      text-align: center;
    }
  }
  .profile-container {
    width: 100%;
    padding: 10px;
    text-align: left;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: column;
    }
    .address-profile-blockies {
      border-radius: 15px;
    }
    .profile-image {
      padding-right: 20px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        padding-right: 0px;
        margin: auto;
      }
    }
    .information {
      padding: 0px 20px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        text-align: center;
        padding: 0px 0px;
      }
      .code {
        font-size: 1.3rem;
        margin: 10px;
        font-weight: 900;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 20px auto;
        }
        .ref-link {
          margin-left: 1rem;
        }
      }
      .balance-action-container {
        margin: auto;
        display: flex;
        justify-content: flex-start;
        text-align: center;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          flex-direction: column;
          justify-content: flex-start;
          text-align: center;
        }
        .item {
          background-color: rgba(white, 0.05);
          border-radius: 12px;
          padding: 10px 15px;
          margin: 5px 5px 15px;
          font-size: 1.1rem;
          //box-shadow: inset 0px 0px 0px 2px rgba($main-color, 1);
        }
      }
    }
  }
  .referral-container {
    background: rgba(white, 0.15);
    padding: 20px;
    border-radius: 15px;
    display: block;
    width: 97%;
    .referral-inner-container {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      .code {
        background: white;
        color: black;
        padding: 5px 15px;
        margin: 5px 5px 5px 0px;
        border-radius: 10px;
        display: flex;
        align-items: center;
      }
      .ref-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 15px;
        margin: 5px 5px 5px 0px;
        border-radius: 10px;
        color: black;
        &:hover{
          opacity: 0.9;
        }
        &:active{
          opacity: 0.8;
          transform: scale(0.97);
        }
        &.primary {
          background: $main-color;
        }
        &.twitter {
          background: #1EA1F2;
          color: white;

          img {
            transform: scale(0.8);
          }
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 5px;
      opacity: 0.9;
      font-size: 1.4rem;
      color: $main-color;
      span.campaign {
        text-transform: lowercase;
        letter-spacing: 0px;
        font-weight: 100;
        background: $main-color;
        color: white;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 100px;
        font-size: 0.8rem;
        margin-left: 10px;
      }
    }

    .subtitle {
      margin: 10px 0px;
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
  .bignumber-wrapper {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 2px;
  }
  .tab-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem auto;
    .tab {
      border-radius: 100px;
      transition: .1s all ease-in-out;
      &.primary {
        background-color: rgba($main-color, 0.7);
      }
      &.secondary:hover {
        background-color: rgba($main-color, 0.1);
      }
      &.secondary:active {
        background-color: rgba($main-color, 0.2);
      }
    }
  }
  .bbt-collection-container {
    width: 100%;
  }

}

.event-list-container {
  width: 100%;
  .event-list {
    border-radius: 15px;
    background: rgba(white, 0.05);
    .event-item {
      padding: 10px;
      margin: 2px;
      box-shadow: 0px -2px 0px rgba(black, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      div {
        padding: 0px 5px;
      }
      &.title {
        &:hover {
          background: black;
        }
      }
      &.no-event {
        justify-content: center;
      }
      &:hover {
        background: rgba(white, 0.05);
      }
      .blockBumber {
        width: 10%;
      }
      .transactionHash {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 500px;
        width: 35%;

        a, a:visited {
          color: white;
        }
      }
      .refererAddress {
        width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 500px;
      }
      .BNBBonus {
        width: 30%;
        text-align: right;
      }
      .rank {
        width: 20%;
      }
      .action {
        width: 20%;
      }
      .balance {
        width: 15%;
        text-align: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        &.green {
          display: inline-block;
          color: green;
          background: rgba(green, 0.1);
          padding: 3px 10px;
          border-radius: 10px;
        }
        &.red {
          display: inline-block;
          color: red;
          background: rgba(red, 0.1);
          padding: 3px 10px;
          border-radius: 10px;
        }
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        .blockBumber {
          width: 15%;
        }
        .transactionHash {
          width: 50%;
        }
        .action {
          width: 35%;
        }
        .balance {
          width: 20%;
        }
        .BNBBonus {
          width: 40%;
        }
        .rank {
          width: 10%;
        }
        // .refererAddress {
        //   width: ;
        // }
      }
    }
  }
}
.buyChoice {
  padding: 1rem;
  border-radius: 8px;
  background-color: #f6f6f6;
  margin: 5px;
  cursor: pointer;
  &.selected {
    background-color: #e6e6e6;
  }
}

.BBT-container {
  margin: 0px auto;
  text-align: center;
  .gallary-frame {
    min-height: 300px;
    display: inline-block;
    width: 23%;
    padding: 10px;
    position: relative;
    transition: .1s all ease-in-out;
    user-select: none;
    @media screen and (max-width: map-get($breakpoints, large)) {
      width: 30%;
    }
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 47%;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
    img {
      width: 100%;
    }
    .overlay {
      display: none;
    }
    &.unrevealed {
      transition: .2s all ease-in-out;
      &:hover {
        .code {
          transition: .2s all ease-in-out;
          transform: scale(1.05);
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 30px;
        right: 0;
        left: 0;
        background: rgba(black, 0.2);
        backdrop-filter: blur(0px);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .index {
          font-size: 1.3rem;
          text-shadow: 0px 1px 5px rgba(black, 0.5);
          span {
            display: block;
            font-size: 0.8rem;
            background: rgba($main-color, 0.8);
            backdrop-filter: blur(5px);
            border-radius: 15px;
            padding: 2px 10px;
            color: black;
            box-shadow: 0px 2px 5px rgba(black, 0.2);
          }
        }
      }
    }
    &.revealed {
      cursor: pointer;
      &:active {
        transition: 0s all ease-in-out;
        transform: scale(0.98);
      }
      &:hover {
        transition: .1s all ease-in-out;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(black, 0.1);
          backdrop-filter: blur(3px);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .index {
            font-size: 1.3rem;
            span {
              display: block;
              font-size: 0.8rem;
              background-color: $main-color;
              border-radius: 15px;
              padding: 2px 10px;
              color: black;
              box-shadow: 0px 2px 5px rgba(black, 0.2);
            }
          }
        }
      }
    }
  }
}
