
.announcement-banner-top {
  color: white;
  z-index: 100021;
  text-align: center;
  position: fixed;
  width: 100%;
  top: -30px;
  right: 0;
  left: 0;
  animation: show-banner 0.3s ease-in-out forwards;
  animation-delay: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 5px;
  background-image: linear-gradient(97deg, rgb(15, 143, 98) 25%, rgb(43, 180, 126) 50%, rgb(15, 143, 98) 75%, rgb(43, 180, 126) 99.32%);
  transition: background-position 350ms ease-in-out 0s;
  background-size: 200%;
  @media screen and (max-width: map-get($breakpoints, small)) {
    font-size: 0.8rem;
  }
  &:hover{
    //opacity: 0.95;
    background-position: right center;
  }
  &:active{
    opacity: 1;
  }
  .new {
    color: black;
    background: white;
    //color: #22cf47;
    //background: darken(#22cf47, 40%);
    font-size: 0.6rem;
    padding: 1px 7px;
    border-radius: 5px;
    margin-right: 5px;
  }
  .pointer {
    margin-left: 5px;
    text-decoration: underline;
    //animation: heartbeat 1s infinite;
    &:hover{
      opacity: 0.7;
    }
    &:active{
      opacity: 1;
    }
  }
}

@Keyframes show-banner {
  0% {
    top: -30px;
  }
  100% {
    top: 0px;
  }
}

@Keyframes banner-push {
  0% {
    top: 0px;
  }
  100% {
    top: 30px;
  }
}

.navbar-fixed-top {
  position: fixed;
  width: 100%;
  background: rgba($black, 0.5);
  backdrop-filter: blur(10px);
  z-index: 100020;
  top: 0px;
  right: 0;
  left: 0;
  animation: banner-push 0.3s ease-in-out forwards;
  animation-delay: 0.5s;
}

.navbar-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 0px rgba(white, 0.075);
  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    padding: 15px 15px;
    &:first-child {
      .myWallet {
        display: none;
      }
    }
  }
  .item {
    user-select: none;
    text-decoration: none;
    font-weight: 500;
    color: rgba(white, 0.6);
    font-size: $nav-font-size;
    padding: 8px 12px;
    border-radius: 30px;
    margin: 0px 3px;
    span.new {

    }
    &:hover, &.active, &.site-title {
      color: rgba(white, 1);
      transition: .1s opacity ease-in-out;
    }
    &:active {
      transform: scale(1.1);
    }
    &.site-title {
      font-size: 1.2rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        max-height: 40px;
      }
    }
    &.myWallet {
      display: block;
      align-self: center;
      font-size: 0.9rem;
      box-shadow: 0px 0px 0px 1.5px rgba(white, 0.3);
      color: rgba(white, 1);
      margin-right: 10px;
      padding: 8px 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.true {
        color: $main-color;
      }
      &:hover{
        color: rgba($main-color, 1);
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    .navbar-container {
      display: flex;
      flex-direction: row;
      justify-content:center;
      padding: 10px;
      .myWallet {
        display: none;
      }
      &:first-child {
        width: 100%;
        padding: 10px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .myWallet {
          display: block;
          align-self: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .item {
      font-size: 1rem;
      padding: 5px 10px;
      margin: 0px 1px;
    }
  }

}
