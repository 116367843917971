.page-container.bbt-details-container {
  margin-top: 110px !important;
}
.bbt-details-container {
  text-align: center;
  a {
    color: white;
    cursor: pointer;
    &:hover {
      color: rgb(215, 215, 215);
    }
  }
  .owner-actions {
    background-color: rgba(white, 0.05);
    border-radius: 15px;
    padding: 20px;
    max-width: 400px;
    margin: 50px auto;

    .owner-info {
      font-size: 0.9rem;
      small {
        opacity: 0.7;
        font-size: 0.7rem;
      }
    }
    button {
      width: 100%;
      border: none;
      background-color: rgba($main-color, 1);
      color: black;
      cursor: pointer;
      padding: 10px;
      border-radius: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        background-color: lighten($main-color, 10%);
      }
      &:active{
        background-color: darken($main-color, 10%);
      }
      &:focus {
        outline: none;
      }
      &:disabled {
        background-color: lighten($main-color, 10%);
        cursor: default;
        opacity:0.3;
      }

      &.red {
        margin-top: 20px;
        background-color: rgba(#da4d4d, 0.8);
        color: white;
        &:hover{
          background-color: rgba(#da4d4d, 1);
        }
      }
      &.green {
        margin-top: 20px;
        background-color: rgba(#24F0CE, 0.5);
        color: white;
        &:hover{
          background-color: rgba(#24F0CE, 0.7);
        }
      }
    }
  }

  .bearnbear-artwork-container {
    .bearnbear-artwork {
      height: 500px;
      max-width: 100%;
      margin-bottom: 20px;
      margin-top: 20px;

      @media screen and (max-width: map-get($breakpoints, small)) {
        height: auto;
        width: 100%;
      }
    }

    .index {
      background: none;
      color: white;
      border: 1px solid white;
      font-size: 0.9rem;
      padding: 3px 10px;
      border-radius: 10px;
      display: inline-block;
      opacity: 0.5;
      margin-top: 5px;
    }

    h2 {
      margin-top: 0px;
    }

    .description {
      max-width: 400px;
      margin: 20px auto;
    }

    .ownerAddress {
      opacity: 0.5;
      font-size: 1rem;
      margin-top: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: white;
        &:hover{
          opacity: 0.8;
        }
      }
    }
    .accumulated {
      opacity: 0.5;
      font-size: 1rem;
      margin: 10px;
    }
  }
}

.traits-group {
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .item {
    cursor: pointer;
    text-align: left;
    background: rgba(white, 0.1);
    padding: 20px;
    border-radius: 15px;
    display: inline-block;
    margin: 5px;
    width: 12%;
    min-width: 129px;
    .title {
      margin-bottom: 5px;
      font-size: 1.2em;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
