@import '../../../assets/scss/style';

.addeventatc {
  border-radius: 10px !important;
  outline: none !important;
  transform: scale(1.2) !important;
  &:hover{
    transform: scale(1.3) !important;
  }
  &:active{
    transform: scale(1.2) !important;
  }
}

.hero-component {
  width: 100%;
  height: 60vh;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica,
  ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  background: black;
  //background: $main-color;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  text-align: center;
  .title-container {
    margin: 0px auto;
    text-align: center;
    padding: 25vh 50px;
    max-width: 1000px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 35vh 10px;
      width: 90%;
    }

    .title {
      font-size: 8rem;
      color: $main-color;
      font-weight: 700;
      //font-family: 'Bowlby One SC', cursive;
      font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: 5rem;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        font-size: 3rem;
      }
    }
    .sub-title {
      font-size: 3rem;
      color: white;
      font-family: 'Kristi', cursive;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        font-size: 2.5rem;
      }
    }
  }
}

.coming-soon {
  .countdown {
    font-size: 8rem;
    font-weight: bold;
    color: $main-color;
    width: 100%;
  }
  .btn-group {
    margin: 20px 0;
    display: flex;
    flex-direction:row;
    justify-content:center;
  }
}
