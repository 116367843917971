.gallary-container {
  .btn {
    padding: 5px 15px;
    font-size: 1rem;
  }
  .gallery-title {
    display: flex;
    flex-direction: row;
    .btn {
      margin-left: 10px;
    }
  }
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    .filter-item {
      background: rgba(white, 0.1);
      padding: 20px;
      border-radius: 15px;
      display: inline-block;
      margin: 5px;
      width: 12%;
      min-width: 129px;
      &:hover, &.active-true{
        background: rgba(white, 0.2);
      }
      .title {
        margin-bottom: 5px;
        font-size: 1.2em;
      }
      &.active-true {
        select {
          background: $main-color;
          color: black;
          &:hover{
            background: rgba($main-color, 0.9);
          }
        }
      }
      select {
        width: 100%;
        outline: none;
        background: rgba(black, 0.7);
        color: white;
        border-radius: 8px;
        padding: 3px 10px;
        cursor: pointer;
        border: 0px solid;
        &:hover{
          background: rgba(black, 1);
        }
      }
    }
  }
}
